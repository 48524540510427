

.consulting-table{
    border-top: 2px solid var(--v-primary-base);
    width: 100%;
}

.consulting-table .thead p{
    padding: 16px;
    justify-content: center;
}

.consulting-table .th{
    font-weight: 700;
}

.consulting-table .th{
    font-size: 1.6rem;
    margin-bottom: 12px;
}

.consulting-table .tr{
    width: 100%;;
    border-bottom: 1px solid var(--border-color);
    padding: 16px;
}

.table-detail{
    width: 100%;
}

.consulting-table .tbody p{
    color: #777;
}

.consulting-table .table-detail>*:nth-child(1){
    font-weight: 700;
}

.consulting-table .tbody .tr>* .table-detail:not(:last-child){
    margin-bottom: 6px;
}

@media (min-width:576px){
}
@media (min-width:768px){
    .table-detail{
        display: flex;
    }
    .table-detail p{
        display: flex;
        align-items: center;
    }
    .consulting-table .table-detail>*:nth-child(1){
        width: 26%;
    }

    .consulting-table .table-detail>*:nth-child(2){
        width: 74%;
    }

    .right-line{
        position: relative;
    }

    .right-line::after{
        content: "";
        display: block;
        position: absolute;
        width: 1px;
        height: 16px;
        background-color: var(--border-color);
        top: 50%;
        right: 0;
        transform: translateY(-50%);
    }
}
@media (min-width:1024px){
    .consulting-tit{
        white-space: nowrap;
    }

    .consulting-table .tbody p{
        padding: 24px 36px;
    }

    .consulting-table .tbody .tr>* .table-detail:not(:last-child){
        border-bottom: 1px solid var(--border-color);
        margin-bottom: 0;
    }

    .consulting-table .th{
        background-color: #fcfcfc;
    }

    .consulting-table .tr{
        display: flex;
        padding: 0;
    }

    .consulting-table .tr>*:nth-child(1){
        width: 20%;
    }

    .consulting-table .tr>*:nth-child(2){
        width: 80%;
    }

    .consulting-table .tbody .table-detail>*:nth-child(1){
        font-weight: 400;
    }

    .consulting-table .tr>*:nth-child(1){
        width: 20%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .consulting-table .th{
        font-weight: 700;
        text-align: center;
    }

    .consulting-table .th{
        font-size: 1.8rem;
        margin-bottom: 0;
    }

    .consulting-item-group .consulting-item:not(:last-child){
        border-right: 1px solid rgba(217, 238, 252, 0.3);
    }
}
@media (min-width:1200px){
}


